import React from "react"
import Layout from "../components/Layout"
import Button from "../components/Button"

import { formWrapper, form, email } from "../styles/contact.module.css"

export default function Contact() {
  return (
    <Layout>
      <section className={`container`}>
        <section className={email}>
          <p>
            <strong>
              For collaborations, commissioned work, <br />
              requests or any other questions contact me on
            </strong>
          </p>
          <p>
            <a href="mailto:katinka@studiokathan.com">
              katinka@studiokathan.com
            </a>
          </p>
        </section>
        <section className={formWrapper}>
          <p>
            <strong>Or fill in the form below</strong>
          </p>
          <form
            className={form}
            name="contact"
            method="POST"
            action="/thank-you/"
            onSubmit="submit"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              placeholder="First name "
              type="text"
              name="first-name"
              required
            />
            <input placeholder="Name " type="text" name="name" required />
            <input placeholder="Subject " type="text" name="subject" />
            <input
              placeholder="Email address "
              type="email"
              name="email"
              required
            />
            <textarea
              placeholder="Message "
              type="text"
              name="message"
              required
            />
            <Button type="submit">Send</Button>
          </form>
        </section>
      </section>
    </Layout>
  )
}
